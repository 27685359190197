import React, { useState } from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import UtilIconTextButtonV2 from '../utils/UtilIconTextButtonV2'
import UtilButtonV2 from '../utils/UtilButtonV2'

type Props = {
  blok: SbBlokData & ISbSectionImageTextV2
}

const SectionImageTextV2 = ({ blok }: Props) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className="container-v2 mx-auto py-16 lg:py-24" {...storyblokEditable(blok)}>
      <div className="items-top flex flex-col gap-8 md:flex-row">
        {/* Image */}
        <div className="relative w-full shrink-0 overflow-hidden rounded-5xl rounded-br-none border border-v2-foreground-static-tertiary md:w-1/2 lg:w-121.5">
          <div className="rounded-bl-2x absolute bottom-0 left-0 w-full">
            <div className="absolute -bottom-56 -left-1/4 h-[300%] w-[150%] bg-v2-secondary-static-2 bg-gradient-to-t from-[#faf2e8f5] to-[#faf2e8f5] blur-1.5xl"></div>
            <div className="relative grid gap-3 px-6 py-8 sm:px-8 lg:px-10 lg:py-10">
              <p className="heading-6 text-v2-text-primary ">{blok.image_title}</p>
              <p className="body-3 text-v2-text-primary">{blok.image_text}</p>
            </div>
          </div>
          <BaseImageV2
            className="block h-full w-full [&_img]:h-full [&_img]:w-full [&_img]:object-cover"
            src={blok.image.filename}
            focus={blok.image.focus}
            alt={blok.image.alt}
            breakPointsWidthMap={{ 0: 680 }}
          />
        </div>

        {/* Text */}
        <div>
          <div className="whitespace-pre-line p-0 lg:p-8">
            <span className="heading-6 mb-2 block text-v2-text-primary">{blok.kicker}</span>
            <h2 className="heading-3 mb-2 text-v2-text-primary">{blok.title}</h2>
            <p className="body-1 mb-6 text-v2-text-secondary">{blok.text}</p>
            {/* Hidden text */}
            <div
              className={`overflow-hidden transition-all duration-300 ease-in-out ${isActive ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}
            >
              <p className="body-1 mb-6 text-v2-text-secondary">{blok.text_hidden}</p>
            </div>

            {/* More button */}
            {!!blok.text_hidden && (
              <UtilIconTextButtonV2
                isIconRotated={isActive}
                text={blok.text_hidden_cta}
                icon="plus"
                onClick={() => setIsActive(!isActive)}
              />
            )}

            {/* Button */}
            {blok.button?.map((button, index) => (
              <div key={index} className="mt-6 w-full text-center lg:w-fit">
                <UtilButtonV2 blok={button} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionImageTextV2
